import React, { useState } from "react";
import { TextField, TextInput, useRecordContext } from "react-admin";
import { MyList } from "../../_myBase/components/myList";
import { MyReferenceField } from "../../_myBase/components/myReference";
import useFieldPreferences from "../../hooks/useFieldPreferences";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";

const AppClipLinkField = (props) => {
  const record = useRecordContext(props);
  const [copied, setCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  let tooltipTimeout = null;

  if (!record) return null;

  const link = `https://appclip.apple.com/id?p=com.harborlockers.droplocker.Clip&locationId=${record.id}`;

  const handleMouseEnter = () => {
    setShowTooltip(true);
    tooltipTimeout = setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout);
    }
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <button
        onClick={handleCopy}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          padding: "0px",
          cursor: "pointer",
          border: "none",
          backgroundColor: copied ? "#e8f5e9" : "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {copied ? (
          <CheckIcon style={{ fontSize: 18, color: "#4CAF50" }} />
        ) : (
          <ContentCopyIcon style={{ fontSize: 18, color: "#666" }} />
        )}
      </button>
      {showTooltip && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            right: 0,
            padding: "4px 8px",
            backgroundColor: "#333",
            color: "white",
            borderRadius: "4px",
            fontSize: "12px",
            whiteSpace: "nowrap",
            zIndex: 1000,
            margin: 1,
          }}
        >
          {link}
        </div>
      )}
    </div>
  );
};

const allFields = [
  { name: "id", component: TextField, props: { sortable: true } },
  { name: "name", component: TextField, props: { sortable: true } },
  { name: "address1", component: TextField, props: { sortable: true } },
  { name: "lat", component: TextField, props: { sortable: true } },
  { name: "lon", component: TextField, props: { sortable: true } },
  {
    name: "pricingPlanId",
    component: MyReferenceField,
    props: {
      label: "Pricing Plan",
      reference: "pricing-plans",
      sortable: false,
    },
  },
  {
    name: "appClipLink",
    component: AppClipLinkField,
    props: {
      label: "AppClip Link",
      sortable: false,
      source: "id",
    },
  },
];

const locationFilters = [
  <TextInput label="Search by Location Name" source="name" alwaysOn key={1} />,
];

export default function LocationList(props) {
  const { visibleFields, loading, updatePreference } = useFieldPreferences(
    "locations",
    allFields.map((field) => field.name)
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  const filteredFields = allFields.filter((field) =>
    visibleFields.includes(field.name)
  );

  return (
    <MyList
      resource="locations"
      {...props}
      filters={locationFilters}
      fields={allFields.map((field) => field.name)}
      onPreferencesUpdate={updatePreference}
    >
      {filteredFields.map(
        ({ name, component: Component, props: fieldProps }) => (
          <Component key={name} source={name} {...fieldProps} />
        )
      )}
    </MyList>
  );
}
