import React from "react";
import {
  TextInput,
  NumberInput,
  DateTimeInput,
  SelectInput,
} from "react-admin";
import { MyCreate } from "../../_myBase/components/myMutator";

const discountTypeChoices = [
  { id: "percentage", name: "Percentage" },
  { id: "fixed", name: "Fixed" },
];

export default function CouponsCreate(props) {
  return (
    <MyCreate {...props}>
      <TextInput source="code" label="Code" />
      <SelectInput
        source="discount_type"
        label="Discount Type"
        choices={discountTypeChoices}
      />
      <NumberInput source="discount_value" label="Discount Value" />
      <NumberInput source="min_purchase_amount" label="Min Purchase Amount" />
      <NumberInput source="usage_limit" label="Usage Limit" />
      <DateTimeInput source="valid_from" label="Valid From" />
      <DateTimeInput source="valid_until" label="Valid Until" />
    </MyCreate>
  );
}
